.paper-page {
  &--back-button {
    position: absolute;
    z-index: 3;
    top: calc(4px + var(--ion-safe-area-top, 0));
    left: 4px;

    ion-back-button {
      --background: rgba(0, 0, 0, 0.5);
      --ion-color-base: #ccc !important;
    }
  }

  &--layout {
    position: relative;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #000;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;

    --info-size: 56px;

    @media (max-width: 760px) {
      flex-direction: column;
      --info-size: 56px;
    }
  }

  &--previews {
    position: relative;
    flex: 1 1 calc(100% - var(--info-size, 56px));
    width: calc(100% - var(--info-size, 56px));

    transition: all 0.5s ease-in-out;

    @media (max-width: 760px) {
      width: 100%;
      height: calc(100% - var(--info-size, 56px));
    }

    .swiper {
      --bullet-background: #fff;
      --swiper-pagination-bullet-inactive-opacity: 0.4;
      --bullet-background-active: #fff;

      .swiper-pagination {
        bottom: 0px;
        padding: 0px 0 4px;
        background: #0006;
      }

      .swiper-pagination-bullet:last-child {
        position: relative;
        top: 1px;
        border-radius: 0;
        width: 2px;
        height: 10px;
        margin: 0 7px;

        &::after {
          content: '';
          position: absolute;
          top: 4px;
          left: -4px;
          width: 10px;
          height: 2px;
          background: #fff;
        }
      }
    }
  }

  &--image-button-container {
    position: absolute;
    z-index: 2;
    top: var(--ion-safe-area-top, 0);
    right: 0;
  }

  &--prev-button-container,
  &--next-button-container {
    position: absolute;
    z-index: 2;
    bottom: calc(50% - 24px - var(--ion-safe-area-top, 0));
    overflow: hidden;
  }
  
  &--prev-button-container {
    left: 0;

    ion-button {
      margin-left: -12px;
    }
  }

  &--next-button-container {
    right: 0;

    ion-button {
      margin-right: -12px;
    }
  }

  &--image-button {
    --border-radius: 50%;
    border-radius: 50%;
    min-width: 48px;
    height: 48px;

    --background: rgba(0, 0, 0, 0.1);;
    --background-focused: rgba(0, 0, 0, 0.5);;
    --background-focused-opacity: 0.12;
    --background-hover: rgba(0, 0, 0, 0.5);;
    --background-hover-opacity: 0.04;
    --color: #fff;
    --ion-color-base: #fff;
    --icon-margin-end: 0;
    --icon-margin-start: 0;
    --icon-font-size: 24px;
    --icon-font-weight: normal;
    --min-height: 32px;
    --min-width: 44px;
    --padding-start: 12px;
    --padding-end: 12px;
    font-size: 14px;
    font-weight: 500;

    background: rgba(0, 0, 0, 0.5);

    &::part(native) {
      box-shadow: none;
    }

    ion-icon + ion-icon {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(11px) scale(0.55) translateX(-0.6px);
    }
  }

  &--info {
    position: relative;
    background-color: var(--ion-background-color, #fff);
    border-radius: 24px 0 0 24px;

    display: flex;
    flex-direction: column;

    min-width: var(--info-size, 56px);

    transition: all 0.5s ease-in-out;

    @media (max-width: 760px) {
      border-radius: 24px 24px 0 0;
      min-width: 100%;
      min-height: var(--info-size, 56px);
    }

    // Desktop mode (vertical)
    @media (min-width: 761px) {
      flex-direction: column;
      min-width: var(--info-size, 56px);
      padding-left: 56px;

      --ion-safe-area-left: 0px;
    }

    // mobile mode (horizontal)
    @media (max-width: 760px) {
      // ...
    }

    .update-button {
      text-transform: none;
      margin-right: 12px;
      --padding-end: 12px;
      --padding-start: 12px;
    }
    .cancel-button {
      text-transform: none;
      margin-right: 6px;
    }
  }

  // Toolbar
  &--toggle-toolbar {
    padding-top: 0;
    cursor: pointer;
    --ion-toolbar-background: transparent;

    // Desktop mode (vertical)
    @media (min-width: 761px) {
      transform-origin: 0 0;
      transform: rotate(90deg);
      width: 100vh;
      position: absolute;
      padding-left: var(--ion-safe-area-top, 0);

      ion-button {
        transform: rotate(180deg);
      }

      ion-buttons[slot='end'] ion-button {
        transform: rotate(90deg);
      }
    }
  }

  &--layout-open {
    --info-size: calc(100% - 260px);
    @supports (right: Max(0px,1px)) {
      --info-size: Min(56vh, calc(100vh - 260px));
    }

    @media (min-width: 761px) {
      --info-size: 540px;
      @supports (right: Min(0px,1px)) {
        --info-size: Min(540px, 50vw);
      }
    }
  }

  &--title {
    padding: 0 4px;
    padding-inline: 4px;

    font-size: 16px;
  }

  &--info-container {
    height: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    @media (min-width: 761px) {
      position: absolute;
      top: 0;
      left: 56px;
      right: 0;
      bottom: 0;
      min-width: 320px;
    }
  }
}

.react-transform-component {
  align-items: center;
  justify-content: center;
}

.swiper {
  width: 100%;
  height: 100%;
}

.preview {
  &--container {
    width: 100%;
  }
  &--image-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: var(--ion-safe-area-top, 0);
    height: calc(100vh - 10px - var(--ion-safe-area-top, 0));
    transition: all 0.5s ease-in-out;

    @media (max-width: 760px) {
      height: calc(100vh - 86px - var(--ion-safe-area-top, 0) - var(--info-size, 0));
    }
  }
  &--image {
    height: 100%;
    width: 100%;
    image-rendering: optimizeQuality;
  }
  &--string {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: rgba(var(--ion-color-secondary-rgb, 181, 223, 215), 0.5);
    border-radius: 1px;
    box-shadow: 0 0 0px 0.8px var(--ion-color-secondary, #b5dfd7);
    cursor: pointer;
  }
  &--recognized {
    white-space: normal !important;
    font-weight: 600;
  }
}
