/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #155e8d;
  --ion-color-primary-rgb: 21, 94, 141;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #0e4062;
  --ion-color-primary-tint: #277db6;

  /** secondary **/
  --ion-color-secondary: #b5dfd7;
  --ion-color-secondary-rgb: 181, 223, 215;
  --ion-color-secondary-contrast: #0b2b2b;
  --ion-color-secondary-contrast-rgb: 43, 33, 11;
  --ion-color-secondary-shade: #85b0a8;
  --ion-color-secondary-tint: #d2ece7;

  /** tertiary **/
  --ion-color-tertiary: #f3dfc4;
  --ion-color-tertiary-rgb: 243, 223, 196;
  --ion-color-tertiary-contrast: #2b210b;
  --ion-color-tertiary-contrast-rgb: 43, 33, 11;
  --ion-color-tertiary-shade: #ceb89c;
  --ion-color-tertiary-tint: #fff1de;

  /** success **/
  --ion-color-success: #20914d;
  --ion-color-success-rgb: 32, 145, 77;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #145c31;
  --ion-color-success-tint: #2ea15c;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  
  --ion-tab-bar-background: #b5dfd7;
  --tips-background: #fdefcd;
}

/** Ionic CSS Variables **/
:root.theme-classic {
  /** primary **/
  --ion-color-primary: #800a0a;
  --ion-color-primary-rgb: 128, 10, 10;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #6d0b0b;
  --ion-color-primary-tint: #920c0c;

  /** secondary **/
  --ion-color-secondary: #ffc946;
  --ion-color-secondary-rgb: 255, 201, 70;
  --ion-color-secondary-contrast: #2b210b;
  --ion-color-secondary-contrast-rgb: 43, 33, 11;
  --ion-color-secondary-shade: #ddad3d;
  --ion-color-secondary-tint: #fee8b6;

  /** tertiary **/
  --ion-color-tertiary: #87b5b3;
  --ion-color-tertiary-rgb: 135, 181, 179;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #70a09d;
  --ion-color-tertiary-tint: #bbe8e7;
  
  --ion-tab-bar-background: #fdd985;
  --tips-background: #bce5fd;
}

/**
  * Dark Colors
  * -------------------------------------------
  */
@mixin comon-dark-colors {
  --ion-color-primary: #ebebeb;
  --ion-color-primary-rgb: 152, 154, 162;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #86888f;
  --ion-color-primary-tint: #a2a4ab;

  --ion-color-secondary-shade: #2b3a38;
  --ion-color-secondary-tint: #495f5c;

  --ion-color-tertiary: #77966e;
  --ion-color-tertiary-rgb: 119, 150, 110;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d7a55;
  --ion-color-tertiary-tint: #91b388;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #e25a60;
  --ion-color-danger-rgb: 226, 90, 96;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #bd464c;
  --ion-color-danger-tint: #e8676e;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-backdrop-opacity: 0.7;
  --ion-backdrop-color: #181815;
}

@mixin dark-modern {
  @include comon-dark-colors;

  --tab-icon-fill-color: #536665;
  --ion-tab-bar-background: #182f2d;
  --ion-tab-bar-color-selected: #fff;
  --ion-color-secondary-tint: #748281;

  ion-popover {
    --ion-background-color: #111f21;
    --ion-item-background: #111f21;
  }

  ion-modal ion-toolbar {
    --ion-toolbar-background: #182f2d;
  }

  .menu {
    --background: #182f2d;
  }

  .menu ion-item ion-label {
    --ion-color-base: #fff;
  }

  ion-segment-button {
    --indicator-color: #182f2d;
  }

  ion-checkbox {
    --background-checked: #4f9acc;
    --border-color-checked: #3580b2;
    --checkmark-width: 2;
  }

  ion-radio.ion-color-primary {
    --ion-color-base: #4f9acc !important;
  }
}

@mixin dark-classic {
  @include comon-dark-colors;

  --tab-icon-fill-color: #666153;
  --ion-tab-bar-background: #2f2918;
  --ion-tab-bar-color-selected: #fff;
  --ion-color-secondary-tint: #837f75;

  ion-popover {
    --ion-background-color: #211d11;
    --ion-item-background: #211d11;
  }

  ion-modal ion-toolbar {
    --ion-toolbar-background: #2f2918;
  }

  .menu {
    --background: #2f2918;
  }

  .menu ion-item ion-label {
    --ion-color-base: #fff;
  }

  ion-segment-button {
    --indicator-color: #2f2918;
  }

  ion-checkbox {
    --background-checked: #504528;
    --border-color-checked: #504528;
    --checkmark-width: 2;
  }

  ion-radio.ion-color-primary {
    --ion-color-base: #75602b !important;
  }
}

@mixin common-dark-styles {
  &:not(.dark-mode-off) {
    background: #000;

    .list-item__thumb-container,
    .list-item__avatar {
      opacity: 0.8;
    }

    .contact__avatar ion-img {
      opacity: 0.7;
    }

    .text-field .input-clear-icon, .total-field .input-clear-icon {
      opacity: 0.5;
    }

    div.tip {
      background-color: #1c3360;
    }

    @media (max-width: 760px) {
      .paper-page--info {
        border-top: 1px solid var(--tab-icon-fill-color, #fff);
      }
    }
    @media (min-width: 761px) {
      .paper-page--info {
        border-left: 1px solid var(--tab-icon-fill-color, #fff);
      }
    }
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  &.ios:not(.dark-mode-off) body {
    --ion-background-color: #121111;
    --ion-background-color-rgb: 18, 17, 17;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #121111;

    --ion-card-background: #1c1c1d;
  }

  &.ios:not(.dark-mode-off) ion-modal {
    /* --ion-background-color: var(--ion-color-step-100); */
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  &.md:not(.dark-mode-off) body {
    --ion-background-color: #121111;
    --ion-background-color-rgb: 18, 17, 17;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #121111;

    --ion-card-background: #1e1e1e;
  }
}

// dark mode on by default
html.dark-mode-on {
  @include common-dark-styles;
}

html.dark-mode-on body {
  @include dark-modern;
}

// classic
html.dark-mode-on.theme-classic body {
  @include dark-classic;
}

// default dark colors
@media (prefers-color-scheme: dark) {
  html:not(.dark-mode-off) {
    body {
      @include dark-modern;
    }

    &.theme-classic {
      body {
        @include dark-classic;
      }
    }

    @include common-dark-styles;
  }
}
