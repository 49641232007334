.filters-toolbar {
  --min-height: 38px;

  &--button.in-toolbar {
    text-transform: none;
    font-size: 14px;
    letter-spacing: 0;
  }
  &--icon {
    font-size: 16px;
  }
  &--selected-item {
    font-weight: 700;
  }
}
