.settings {
  &-page {
    &::part(scroll) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 100%;
    }

    &--version {
      text-align: center;
      font-size: 12px;
      font-weight: 900;
      padding: 6px 0 12px;
      opacity: 0.5;

      @media (min-width: 760px) {
        padding: 6px 0 calc(var(--ion-safe-area-bottom, 0) + 12px);
      }

      &:hover {
        opacity: 1;
      }
    }
  }
  
  &-grid {
    max-width: 1000px;
    margin: 0 auto;
    padding-right: var(--ion-safe-area-right, 0);
  }

  &--username {
    background: transparent;
    --ion-item-background: transparent;
  }

  &--user-button {
    text-transform: none;
  }

  &--login-button {
    height: auto;
    white-space: normal;
    --padding-top: 4px;
    --padding-bottom: 3px;

    &-label {
      line-height: 1.2rem;
    }
  }

  &--legal-button {
    white-space: normal;
    height: auto;
    --padding-top: 3px;
    --padding-bottom: 3px;
    hyphens: auto;
  }

  &--support-button {
    white-space: normal;
    height: auto;
    --padding-top: 5px;
    --padding-bottom: 5px;
    hyphens: auto;
  }

  &--badge {
    position: absolute;
    top: 12px;
    right: 12px;
  }
  &--button-ellipsis {
    --padding-start: 0.2rem;
    --padding-end: 0.2rem;

    ion-icon {
      font-size: 1rem;
    }
  }
}