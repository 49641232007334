@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/Alegreya-Bold.ttf') format('truetype');
}

.app-name {
  position: relative;
  min-height: 4rem;
  padding: 1rem;

  &__title {
    margin: 0;
    font: 700 2.2rem/2rem Alegreya, serif;
    color: var(--ion-color-primary, #800a0a);
    letter-spacing: -1.6px;

    .side-menu__shrink & {
      display: none;
    }
  }
}

.menu-shrinker {
  position: absolute;
  right: 8px;

  .side-menu__shrink & {
    right: 14px;
    margin: 0 auto;
    --padding-start: 0;
    --padding-end: 0;
  }
}

ion-split-pane {
  --side-min-width: 20px;
  --side-max-width: 270px;

  ion-toolbar.toolbar-segment.md {
    max-width: 760px;
    margin: 0 auto;
  }

  ion-content ion-list,
  ion-content ion-list.list-md {
    width: 100%;
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
  }

  ion-content ion-segment {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }

  ion-content .sticky-bar {
    position: sticky;
    top: 0;
    z-index: 3;
    background: var(--ion-background-color, #fff);
  }

  .ion-page {
    // transition: all 0.3s ease-in-out;
  }
}

ion-menu {
  width: 270px;
  transition: width 0.1s ease-in;

  ion-label {
    --ion-font-family: Roboto, Arial, sans-serif;
  }

  &.side-menu__shrink {
    width: calc(60px + var(--ion-safe-area-left, 0));

    ion-icon[slot='start'] {
      margin-inline-end: 0px !important;
    }
  }
}

ion-tab-button {
  --background: transparent;
}

ion-tab-bar ion-label {
  font-family: Roboto, Arial, sans-serif;
  font-size: 13px;
}

.menu__item-selected ion-icon,
.tab-selected ion-icon {
  --tab-icon-fill-color: var(--ion-color-tertiary-tint, #fff1de);
}

ion-modal {
  @media (min-width: 760px) {
    &::part(content) {
      border-radius: 6px;
    }
  }
  ion-toolbar {
    --ion-toolbar-background: var(--ion-tab-bar-background, #b5dfd7);
    background: #fff;

    ion-title {
      padding-left: 4px;
    }
  }

  ion-list {
    padding-bottom: var(--ion-safe-area-bottom, 8px) !important;
  }
}

ion-item.option-hint {
  --border-style: none;

  ion-label {
    white-space: normal !important;
  }
}

ion-item-option {
  text-transform: none;
}

/* Navigation */
#main {
  position: relative;
  width: 100%;
}

.menu {
  height: 100%;
  --background: var(--ion-tab-bar-background, #b5dfd7);
  border-inline-color: var(--ion-color-secondary-tint, #85b0a8);

  &__list {
    background: transparent;
  }

  &__item {
    --background: transparent;

    &-selected {
      --background: rgba(255, 255, 255, 0.4);
    }

    &-collapse {
      --padding-start: 0;
    }
  }
}

.menu-items {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: var(--ion-safe-area-top, 0);
}

.menu-icon {
  margin-inline-end: 12px;
  font-size: 36px;
}

ion-tab-bar {
  height: 64px;

  ion-label {
    font-size: 11px;
  }
}

.tab-icon {
  font-size: 36px;
}

.tabs-hidden {
  display: none;
}

/* Toolbars */
ion-toolbar.search-toolbar ion-buttons ion-button.button-has-icon-only.add-button {
  --padding-start: 8px;
  --padding-bottom: 8px;
  --padding-end: 8px;
  --padding-top: 8px;

  .add-button--icon {
    font-size: 2rem;
  }
}

.toolbar-buttons .add-button ion-label {
  margin: 0 0.3rem;
}

ion-modal .buttons-last-slot {
  margin-right: 8px;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}
.header-md::after {
  content: none;
}
ion-header {
  border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  background: var(--ion-background-color, #fff);
  box-shadow: none !important;
}
ion-toolbar {
  max-width: 1000px;
  margin: 0 auto;

  ion-modal & {
    max-width: none;
  }
}

.toolbar-searchbar {
  padding-bottom: inherit !important;
}

.search-toolbar > .toolbar-searchbar {
  padding-right: 0 !important;
}

ion-toolbar.toolbar-segment.md {
  --padding-start: 12px;
  --padding-end: 12px;
}

.segment-toolbar {
  --min-height: 32px;
  padding-bottom: 12px;
}
ion-segment-button {
  text-transform: none;
}

/**
 * Floating Action Buttton
 */
ion-fab.fab-horizontal-end {
  right: calc(10px + var(--ion-safe-area-right, 0px));

  &.modal-action {
    bottom: calc(10px + var(--ion-safe-area-bottom, 0px));
  }

  @media (min-width: 1200px) {
    top: 10px;
    bottom: auto;

    ion-fab-list.fab-list-side-top {
      top: 0px;
      bottom: auto;
      flex-direction: column;
    }
  }
}
ion-fab.fab-horizontal-start {
  left: calc(10px + var(--ion-safe-area-right, 0px));

  @media (min-width: 1200px) {
    top: 10px;
    bottom: auto;
  }
}
@supports (right: Max(0px,1px)) {
  ion-fab.fab-horizontal-end {
    right: Max(calc(10px + var(--ion-safe-area-right, 0px)), calc(50% - 500px));
  }
  ion-fab.fab-horizontal-start {
    left: Max(calc(10px + var(--ion-safe-area-right, 0px)), calc(50% - 470px));
  }
}

/* FABs in the modals */
ion-modal ion-fab.fab-horizontal-end {
  right: 10px;
  top: auto;
  bottom: 10px;
}

.fab-tooltip {
  position: relative;

  &--text {
    position: absolute;
    right: 24px;
    top: -45px;
    width: auto;
    white-space: nowrap;

    color: var(--ion-color-primary, #800a0a);
    --background: var(--ion-color-light, #f4f5f8);
    box-shadow: 0px 2px 14px -2px rgba(var(--ion-color-dark-rgb, 34, 36, 40), 0.3);

    @media (min-width: 1200px) {
      top: 5px;
    }
  }
}
ion-fab-button::part(native) {
  box-shadow: 0px 2px 8px -2px rgba(var(--ion-color-dark-rgb, 34, 36, 40), 0.6);
}

/* Tips */
.tip {
  max-width: 800px;
  @supports (max-width: Min(0px,1px)) {
    max-width: Min(800px, calc(100% - 24px));
  }
  margin: 16px auto;
  padding: 12px 18px;
  background-color: var(--tips-background, #fdefcd);
  font-size: 14px;
  line-height: 1.5;
  border-radius: 4px;
  box-shadow: 0 6px 12px -2px rgba(0, 0, 0, 0.15);
  transition: opacity 0.2s ease-in-out;

  p {
    margin: 12px 0;
  }

  &--with-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  &--icon {
    width: 38px;
    height: 38px;
    margin-left: -8px;
    margin-right: 18px;
    --fill-color: var(--ion-color-dark, #000);
  }
  &--with-close {
    position: relative;
    padding-top: 28px;
  }
  &--close {
    position: absolute;
    top: -3px;
    right: -3px;
    opacity: 0.6;
  }
}

/* Form elements */
.modal-form {
  @media (min-width: 760px) {
    --width: 70%;
    --height: 80%;
    --ion-safe-area-left: 0px;
    --ion-safe-area-right: 0px;
  }

  &__action-button {
    text-transform: none;
    letter-spacing: 0;
    font-size: 16px !important;
    --border-radius: 18px !important;
    --padding-start: 12px !important;
    --padding-end: 12px !important;
  }
}
html.ios ion-modal ion-toolbar {
  --min-height: 42px;
  padding-right: var(--ion-safe-area-right);
  padding-left: var(--ion-safe-area-left);

  &:not(.toolbar-segment):last-of-type {
    padding-bottom: 0 !important;
  }
}
@media (max-width: 540px) {
  ion-modal ion-toolbar ion-title {
    font-size: 16px;
  }
  ion-segment-button {
    min-width: auto;
  }
}
html.ios ion-toolbar:not(.paper-page--toggle-toolbar):first-of-type,
html.ios ion-modal ion-toolbar:first-of-type {
  padding-top: var(--ion-safe-area-top, 0) !important;
}


ion-button {
  max-width: 100%;

  ion-label {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 540px) {
  ion-button {
    --padding-start: 12px;
    --padding-end: 12px;
  }
}

.text-field,
.total-field {
  margin-top: 8px;
  border-radius: 6px;
  --border-radius: 6px;
  --padding-start: 8px !important;
  --background: var(--ion-color-step-50, #f2f2f2);
  min-height: auto !important;

  .input-clear-icon {
    opacity: 0.2;
  }

  &:hover {
    --background: var(--ion-color-step-100, #e6e6e6);

    .input-clear-icon {
      opacity: 1;
    }
  }

  &.has-focus {
    --background: var(--ion-color-step-150, #d9d9d9);

    .input-clear-icon {
      opacity: 1;
    }
  }

  label.input-wrapper {
    --padding-top: 4px;
    --padding-bottom: 4px;
  }

  input[type="date"] {
    padding: 3px 0;
  }

  &__label {
    --padding-top: 4px;
    --padding-bottom: 4px;
    font-size: 12px;

    &_controls {
      width: 100%;
      padding-right: 28px;
    }

    .input-clear-icon.sc-ion-input-md {
      background-size: 18px;
    }
  }

  &__container {
    --show-full-highlight: 0;
    --border-width: 0;
    --inner-border-width: 0;
    --background-focused: transparent;
    --background-hover: transparent;
    // --color-hover: var(--ion-color-light, #f4f4f4);
  }

  &__controls {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 6px;
  }

  &__label-button {
    position: absolute;
    z-index: 3;
    text-transform: none;
    letter-spacing: 0;
    --box-shadow: none;
  }

  &__options {
    margin-top: 12px;
    --padding-start: 0.2em;
    --padding-end: 0.2em;
    opacity: 0.6;
  }

  &__new {
    width: 100%;
    padding-top: 6px;
    text-align: right;
  }

  &__add {
    text-transform: none;
    letter-spacing: 0;
    --padding-end: 0.5em;
  }

  &__error {
    margin: 0;
    transform: translate3d(0, 10%, 0) scale(0.75) !important;
    transform-origin: 0;
    overflow: visible;
    white-space: normal !important;
  }
}

.linked-paper-field {
  width: 100%;
  margin: 0.4rem 0 0 0;
  padding: 0.6rem;
  cursor: pointer;
  background: var(--ion-color-step-50, #f2f2f2);
  border-radius: 6px;

  &:hover {
    background: var(--ion-color-step-100, #e6e6e6);
  }

  &:active {
    background: var(--ion-color-step-150, #d9d9d9);
  }

  h2 {
    font-size: 1.2rem;
    margin: 0;
  }
  p {
    margin: 0;
  }
}

.total-field {
  font-size: 1.3rem;
  background: var(--ion-color-step-50, #f2f2f2);
  padding: 8px;
  width: 100%;
  border: none;

  &-large {
    font-size: 2rem;
  }

  &:hover {
    background: var(--ion-color-step-100, #e6e6e6);
  }
  &:focus {
    background: var(--ion-color-step-150, #d9d9d9);
  }
  &:focus,
  &:active,
  &:focus-visible {
    border: none;
    box-shadow: none;
    outline: none;
  }

  &__container {
    align-items: flex-end;
  }

  &__currency-container {
    margin-inline-start: 0.5rem;

    &-small {
      margin-bottom: 0px;
    }
  }

  &__currency {
    width: 4rem;
  }
}

.file-field {
  &__input {
    position: absolute;
    z-index: -1;
    visibility: hidden;
  }

  &__list {
    margin-top: 4px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__preview {
    width: 100px;
    height: 138px;
    object-fit: contain;

    &-container {
      position: relative;
      margin: 4px 12px 4px 0;
    }
  }

  &__no-preview {
    display: flex;
    align-items: center;
    text-align: center;

    border: 1px solid var(--ion-color-medium, #999);
    border-radius: 6px;

    &_filename {
      width: 100%;
      padding: 8px;

      font-family: var(--ion-font-family, inherit);
      font-size: 0.85rem;

      overflow-wrap: break-word;
    }
  }

  &__delete {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    --padding-start: 0.2em;
    --padding-end: 0.2em;

    opacity: 0.6;
    cursor: pointer;
  }

  &__button {
    width: 100px;
    height: 138px;
    --padding-end: 20px;
    --padding-start: 20px;
    text-transform: none;
    white-space: normal;
  }
}

ion-item.auth-checkbox {
  margin-top: 12px;

  &::part(native) {
    align-items: flex-start;
  }
}
ion-checkbox.auth-checkbox {
  margin-top: 12px;
  margin-inline-end: 12px;
  vertical-align: top;
  white-space: normal;
}

ion-label.auth-checkbox {
  white-space: normal !important;
}

.md.button {
  text-transform: none;
}

.file-upload-button {
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: var(--ion-color-white, #fff);
  cursor: pointer;

  &__icon {
    width: 56px;
    height: 56px;
  }
}

.select-field__popover {
  --width: auto;
}

.popover-viewport ion-icon[slot='start'] {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-inline-end: 12px;
}

ion-textarea.text-field {
  line-height: 1.5;
}

ion-popover {
  --width: 260px;
  --ion-safe-area-left: 0px;
  --ion-safe-area-right: 0px;

  ion-item {
    font-size: 14px;
    --min-height: 42px;
  }
}

.button-icon {
  &__first {
    transform: translateY(-2px);
  }
  &__second {
    position: absolute;
    transform: translateY(3px);
  }
  &__prev {
    transform: translateX(-2px);
  }
  &__next {
    position: absolute;
    transform: translateX(3px);
  }
}

ion-item.list-item::part(native) {
  align-items: center;
}

.list-item {
  --min-height: 56px;

  &.contacts-list-item {
    height: 66px;
    --min-height: 66px;
  }

  &.sub-item::part(native) {
    --padding-start: 38px;
  }

  &--active {
    --background: var(--ion-color-secondary-tint, #d2ece7);
  }

  &__avatar {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    --border-radius: 8px;
  }

  &__thumb {
    position: relative;
    z-index: 9;
    display: block;
    width: 3rem;
    max-height: 4.5rem;
    object-fit: cover;
    object-position: center;
  }

  &__thumb-container {
    position: relative;
    z-index: 1;
    padding: 1px;
    margin: 0.4rem 1.4rem 0.4rem 0;
    transform: translateX(5px) rotateZ(-2deg);
  }

  &__thumb-frame {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;

    &-0 {
      z-index: 10;
    }
    &-1 {
      transform: rotateZ(6deg);
      z-index: 8;
      opacity: 0.9;
    }
    &-2 {
      transform: translate(0px, -2px) rotateZ(-8deg) scale(0.98);
      z-index: 7;
      opacity: 0.8;
    }
    &-3 {
      transform: translate(-2px, -3px) rotateZ(4deg) scale(1.03);
      z-index: 6;
      opacity: 0.7;
    }
    &-4 {
      transform: translate(-1px, 1px) rotateZ(0deg) scale(1.06);
      z-index: 5;
      opacity: 0.6;
    }
  }

  &__selected-checkmark,
  &__selected-checkmark-background {
    position: absolute;
    z-index: 12;
    top: -8px;
    left: -14px;
    font-size: 32px;
  }

  &__selected-checkmark-background {
    z-index: 11;
  }

  ion-label &__pre-title {
    font-size: 13px;
    line-height: 16px;
  }

  &__issued-to {
    color: var(--ion-color-primary, #800a0a);
  }

  ion-label &__title {
    font-weight: 600;
    font-size: 18px;
  }

  ion-label &__subtitle {
    line-height: 20px;
  }

  &__end {
    text-align: right;
    text-align: end;

    margin-inline-start: 16px;
  }

  &__total {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin: 6px 3px 5px 0;
    min-height: 38px;
    padding-left: 0.5rem;
    font-size: 20px;
    line-height: 18px;
    font-weight: 400;
    font-family: 'Noto Sans', sans-serif;
    letter-spacing: -0.5px;
    text-align: right;

    &-unpaid {
      color: var(--ion-color-danger, #c00);
      white-space: nowrap;

      ion-icon {
        margin-right: 0.1rem;
        font-size: 0.8rem;
        vertical-align: -0.1rem;
      }
    }

    &-income {
      color: var(--ion-color-success, #066f2a);
      white-space: nowrap;

      ion-icon {
        margin-right: 0.2rem;
        font-size: 1rem;
        vertical-align: -0.2rem;
      }

      + span {
        margin-left: 0.5rem;
      }
    }
  }

  &__unpaid,
  &__income {
    margin: 0 0 3px;
  }

  &__requested,
  &__reimbursed {
    font-size: 0.8rem;
    margin-top: 0.2rem;

    ion-icon {
      font-size: 0.8rem;
      vertical-align: -0.1rem;
      margin-right: 0.2rem;

      &.flip {
        transform: scaleX(-1);
      }
    }
  }

  &__requested {
    color: var(--ion-color-dark, #000);
  }

  &__reimbursed {
    color: var(--ion-color-success, #066f2a);

    ion-icon {
      font-size: 1rem;
      vertical-align: -0.2rem;
    }
  }
}

.group-item::part(detail-icon) {
  order: -1;
  margin-left: -3px;
  margin-right: 8px;
}

.list-data {
  margin: 0 0 2px;
  font: 400 14px/18px 'Noto Sans', sans-serif;
  color: rgba(0, 0, 0, 0.4);
  color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.4);

  &--primary {
    font-weight: 700;
    font-size: 18px !important;
    letter-spacing: -0.6px;
  }

  &--primary,
  &--title {
    color: #000 !important;
    color: var(--ion-color-dark, #000) !important;
  }

  &--count {
    padding-left: 8px;
    font-size: 16px;
    color: var(--ion-color-medium, #999);
    font-weight: 400;
  }
}

/**
 * Item details
 */
.details-list,
.form-fields {
  margin-bottom: 66px;
}

.details {
  &__divider {
    --padding-top: 0.6rem;
    --padding-start: 9px;
    --padding-end: 9px;
    border-bottom: none;

    ion-label {
      margin-bottom: 2px;
    }

    &_with-button {
      margin-top: 1rem;
      --padding-end: calc(var(--ion-safe-area-right, 0px) + 12px);
      --inner-padding-end: 0px;

      ion-label {
        margin-bottom: 12px;
      }

      .add-button {
        --padding-start: 3px;
        --padding-end: 3px;
        font-size: 0.8rem;
      }
    }
  }
  &__item {
    align-items: flex-end;
  }
  &__item-value {
    white-space: break-spaces;

    &_large {
      font-size: 1.8rem !important;
      font-weight: 500 !important;
      letter-spacing: -1px;
    }

    &_total,
    &_base,
    &_net {
      font-size: 1.8rem !important;
      font-weight: 400 !important;
      letter-spacing: -2px;

      .currency {
        font-size: 1rem;
        letter-spacing: 0;
      }
    }

    &.amount-notpaid,
    &_total_not_paid {
      color: var(--ion-color-danger, #c00);
    }

    &.amount-paid,
    &_net_paid {
      color: var(--ion-color-success, #066f2a);
    }
  }
  &__actions {
    margin: 11px 0 10px;
  }
  &__action {
    margin: 0 0 0 0.7rem;
    --padding-start: 0.3rem;
    --padding-end: 0.3rem;
  }
  &__action-light {
    opacity: 0.3;
    ion-item:hover & {
      opacity: 1;
    }
  }
  &__action-icon {
    margin-inline-end: 16px;
  }
  &__files {
    padding: 0.6rem 0 0.9rem;
    display: flex;
    flex-direction: row;
    column-gap: 0.6rem;
    overflow: auto;

    &_preview {
      max-height: 7rem;
      min-width: 5rem;
      object-fit: contain;

      &-container {
        position: relative;
        z-index: 1;
        padding: 1px;
        margin: 0.4rem 0.4rem 0.4rem 0.2rem;
      }

      &-frame {
        position: absolute;
        display: block;
        top: 0px;
        left: 1px;
        width: 98%;
        height: 97%;
        background-size: cover;
        cursor: pointer;
      }
    }
  }
}

/*
 * Paper
 */
.paper {
  &__title {
    margin: 0.8rem 2rem 0.2rem;
    padding-right: var(--padding-end);
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5;
  }
  &__reference {
    margin: 0.2rem 2rem;
    padding-right: var(--padding-end);
    text-align: center;
    font-size: 1.2rem;
    cursor: pointer;

    &-copy {
      position: absolute;
      margin-left: 0.4rem;
      vertical-align: baseline;
      opacity: 0.3;
      font-size: 1rem;
    }
    &:hover &-copy {
      opacity: 1;
    }
  }
  &__category {
    margin: 0 2rem -0.5rem 2rem;
    padding-right: var(--padding-end);

    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: var(--ion-color-primary, #6d0b0b);
    opacity: 0.7;
  }
}

/*
 * Contact
 */
.contact {
  &__avatar {
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    margin: 0.8rem auto;
    width: 8rem;
    border-radius: 1rem;
    overflow: hidden;

    padding: 3px;
    background: var(--ion-color-light, #fff);
    box-shadow: 0 0 16px -4px var(--ion-color-medium, #999);

    ion-img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      border-radius: 14px;
      overflow: hidden;
    }
  }

  &__names {
    margin: 0.8rem 2rem;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5;
  }
}

.favorite-star {
  position: absolute;
  margin-left: 0.5rem;
  font-size: 1.2rem;
  cursor: pointer;

  &__pinned {
    margin-top: 0.5rem;
  }

  &__list-item {
    position: relative;
    margin-left: 0.2rem;
    font-size: 0.9rem;
  }
}

/**
 * Stacks
 */
.stack {
  &__image {
    max-width: 300px;
    margin: 24px auto;
    border-radius: 1rem;
    overflow: hidden;
    padding: 3px;
    background: var(--ion-color-light, #fff);
    box-shadow: 0 0 16px -4px var(--ion-color-medium, #999);

    ion-img {
      border-radius: 14px;
      overflow: hidden;
    }
  }
  &__names {
    text-align: center;
    margin: 3rem 2rem 1rem;
  }
  &__image + &__names {
    margin-top: 0;
  }
  &__card {
    margin: 6px;
    height: calc(100% - 12px);
  }
  &__header {
    aspect-ratio: 2 / 1;
    background: var(--stack-color, --ion-color-light);
  }
  &__title {
    font-size: 1.1rem;
    font-weight: bold !important;
    color: var(--ion-color-dark, #000);
    hyphens: auto;
  }
  &__docs {
    float: left;
    margin: 0.5rem 0.2rem 0 0;
    font-size: 2.3rem;
    line-height: 1rem;
    color: var(--ion-color-dark, #000);
  }
  &__description {
    min-height: 3.4rem;
    padding-top: 0.7rem !important;
    font-size: 0.9rem;
    hyphens: auto;
  }
}

/**
 * Settings
 */
.settings {
  &-grid {
    --ion-grid-padding: 6px;
    --ion-grid-column-padding: 0;
    padding-right: var(--ion-safe-area-right, 0);

    @media (max-width: 375px) {
      ion-col {
        flex: 0 0 100%;
        width: 100%;
        min-width: 100%;
      }
    }
  }

  &-card {
    margin: 6px;

    &_clickable {
      cursor: pointer;
    }

    &--title {
      margin: 0 0 8px !important;
      padding-left: 8px;
      text-indent: -16px;
      font-size: 18px !important;
      line-height: 24px;
      font-weight: bold !important;
      color: var(--ion-color-dark, #000);
      hyphens: auto;
    }

    &--description {
      margin: 8px 0 0 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      color: var(--ion-color-medium-shade, #808080);

      ion-button {
        --padding-start: 0.5rem;
        --padding-end: 0.5rem;
      }
    }

    &--icon {
      margin: 0 8px 0 0;
      vertical-align: -5px;
      font-size: 24px;
    }
  }

  &-badge {
    float: right;
    text-indent: 0;
  }
  &--username &-badge {
    margin-top: 12px;
  }
}

.item {
  &--long-description {
    white-space: normal !important;
    height: auto;

    p:not(:first-child) {
      margin: 0.8rem 12px 0 0;
    }
  }
}

/**
 * Pinch Zoom Preview
 */
.paper-preview {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  &__zoom {
    height: calc(100% - 110px);
    width: 100%;
    flex-grow: 1;
    position: relative;
  }

  &__controls {
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    background: rgba(34, 36, 40, 0.5);
    border-radius: 1.2rem 0 0 0;
  }

  &__image {
    max-width: 90%;
    max-height: 90%;
    margin: auto;
    transition: transform 0.2s ease-in;
  }

  &__selector {
    padding: 0.3rem 0 0.6rem;
    display: flex;
    flex-direction: row;
    grid-column-gap: 0.6rem;
    column-gap: 0.6rem;
    overflow: auto;

    .details__files_preview {
      display: block;
      margin: 0 1.6px 1px 1px;
      max-height: 4rem;
      min-width: 2rem;
      object-fit: contain;
    }

    .details__files_preview-container {
      margin: 0.4rem 0rem 0.4rem 0.4rem;

      &__active {
        opacity: 0.3;
      }
    }
  }
}

.react-transform-wrapper,
.react-transform-component {
  height: 100%;
  width: 100%;
  flex-grow: 1;
}

.pin {
  &-text {
    text-align: center;
    font-size: 1rem;
    margin: 1rem 2rem 2rem;
    max-width: 500px;
    min-height: 3rem;
    line-height: 1.5;

    @supports (max-width: Min(0px,1px)) {
      max-width: Min(500px, 80vw);
    }
  }
  &-button {
    width: 4.5rem;
    height: 4.5rem;
    margin: 0.4rem;
    font-size: 1.3rem;
  }
  &-error {
    font-size: 12px;
    color: var(--ion-color-danger, #eb445a);
    margin: -1rem 0 0.8rem 0;
    text-align: center;
  }
  &-buttons {
    text-align: center;
  }
}

.modal-description-text {
  margin: 24px 24px 24px calc(var(--ion-safe-area-left, 0) + 24px);
  line-height: 1.4;
}

.modal-description-bold {
  margin: 16px 16px 16px calc(var(--ion-safe-area-left, 0) + 16px);
  line-height: 1.4;
  font-weight: 500;
}

.modal-description-button {
  margin: 48px;
  padding-bottom: 48px;
  text-align: center;
}

.modal-footer-button {
  margin: 12px 0 22px;
  text-align: center;
}

@media (max-width: 540px) {
  .modal-description-text {
    margin: 16px;
  }
  .modal-description-button {
    margin: 24px;
    padding-bottom: 32px;
  }
}

.dropzone {
  &--backdrop {
    opacity: 0.7;
  }
  &--icon-container {
    position: fixed;
    z-index: 3;
    top: 50%;
    left: 50%;
  }
  &--icon {
    transform-origin: 50% 50%;
    transform: translate(-50%, -50%) scale(2);
    color: #fff;
    --color: #fff;
  }
}

ion-label.empty-search-results.empty-search-results {
  white-space: normal;

  h2 {
    margin-bottom: 0.4rem;
  }
}

.leave-space-for-fab-at-the-bottom {
  padding-bottom: 64px;
  transition: opacity 0.2s ease-in-out;

  &.list-virtualized > div {
    padding-bottom: 76px;
  }
}

.list-transparent {
  opacity: 0.2;
}

.list-full-height {
  height: 100%;
}

ion-action-sheet.choose-file-source {
  --button-color: var(--ion-color-dark, #000);

  .action-sheet-button-inner.sc-ion-action-sheet-ios {
    justify-content: flex-start;
  }
  .action-sheet-button.sc-ion-action-sheet-ios {
    font-size: 18px;
  }
}

.ocr {
  position: absolute;
  z-index: 9;
  bottom: 16px;
  right: 16px;
  pointer-events: none;

  @media (max-width: 760px) {
    bottom: 80px;
  }

  &-stopped {
    opacity: 0;
  }

  &-working {
    opacity: 1;
    animation: blink-animation 1s infinite;
  }
}

@keyframes blink-animation {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.import-contact-item {
  ion-checkbox.matched-contact {
    align-self: flex-start;
  }

  .list-item__end {
    align-self: flex-start;
  }

  &--only-contact {
    margin-top: 1.1rem;
  }
}

ion-label .matched-contact {
  &--title {
    display: block;
    margin: 0.6rem 0 0rem;
    font-size: 0.7rem;
    line-height: 0.7rem;
    opacity: 0.5;
  }

  &--avatar {
    display: inline-block;
    overflow: hidden;

    max-width: 24px;
    max-height: 24px;
    font-size: 24px;
    object-fit: contain;

    margin: 0.5rem 0.5rem 0.2rem 0.2rem;
    vertical-align: -0.6rem;

    border-radius: 0.2rem;
  }
}

.matched-contact--sub-item {
  margin-left: 3.5rem;
  font-size: 0.9rem;
}

.extracted {
  &--button {
    position: fixed;
    bottom: 0.6rem;
    width: 100%;

    margin: 0;
    text-align: center;
    opacity: 1;
    transition: opacity 0.6s ease-in;

    // in case it's desktop mode
    @media (min-width: 761px) {
      width: calc(var(--info-size, 50vw) - 56px);
    }

    ion-button {
      backdrop-filter: blur(5px);
      // background: rgba(var(--ion-color-light-rgb, 255, 255, 255), 0.7);
    }
  }

  &--line {
    --min-height: 48px;

    ion-label p {
      color: var(--ion-color-dark, #000);
    }

    &.list-item::part(native) {
      --padding-start: 16px;
    }

    .details__actions {
      margin: 4px 0;
    }
  }

  &--line-3.list-item::part(native) {
    --padding-start: 72px;
  }
}

.paper-page--layout:not(.paper-page--layout-open) .extracted--button {
  opacity: 0;
  pointer-events: none;
}

// Welcome modal

.welcome-modal {
  &--swiper {
    --bullet-background: #000;
  }

  &--slide {
    padding: 2rem 2rem 4rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  h1 {
    margin: 0 0 0.4rem;

    font-size: 2.2rem;
    line-height: 2rem;
    font-weight: 700;
  }

  &--lead {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 700;
  }

  &--note {
    font-size: 0.9rem;
    font-weight: 400;
  }

  .swiper-pagination-bullets {
    margin-bottom: var(--ion-safe-area-bottom, 10px) !important;
  }
}

.checkbox--updated {
  --ion-color-base: var(--ion-color-primary, #155e8d) !important;
  --ion-color-contrast: var(--ion-color-primary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb, 255, 255, 255) !important;
  --border-color: var(--ion-color-primary, #155e8d) !important;
  --checkbox-background: rgba(var(--ion-color-primary-rgb, 21, 94, 141), 0.3) !important;
}

/**
 * Multiple column view
 */
@media (min-width: 1024px) {
  .split-pane-visible {
    [data-pane] {
      transition: all 0.2s ease-in-out;
    }

    [data-pane="left"] {
      display: block !important;
      right: calc(100% - 480px);
    }

    [data-pane="left"] ~ [data-pane="right"],
    [data-pane="right"]:has(~ [data-pane="left"]) {
      left: 480px;
    }
  }
}
