.period-toolbar {
  --min-height: 42px;

  &--title {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
}

.selected-period {
  font-weight: 700;
  color: var(--ion-color-primary);
}
