.papers-selection-toolbar {
  --min-height: 42px;
  --background: rgba(var(--ion-color-primary-rgb, 128, 10, 10), 0.05);
  border-radius: 12px 12px 0 0;

  &--title {
    padding-inline: 0 20px;
    padding-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
  }

  &--stack-icon {
    transform: rotate(90deg);
  }
}
